<template>
    <div>
        <div>
            <span class="detailsTitle">设备信息</span>
            <span style="cursor: pointer" @click="visible = true"><a-icon type="edit" /> 编辑设备信息</span>
            <div class="detailsList">
                <ul>
                    <li>设备所属产品：{{ equipInfo.productName }}</li>
                    <li>设备节点类型：{{ equipInfo.nodeType | nodeTypeFilter }}</li>
                    <li>最近在线时间：{{ equipInfo.latestOnlineTime }}</li>
                    <li>设备密匙(DeviceKey)：{{ equipInfo.keyInfo }}</li>
                    <li>设备描述：{{ equipInfo.remark }}</li>
                    <li>位置信息：{{ equipSite }}</li>
                </ul>
                <ul>
                    <li>设备code：{{ equipInfo.code }}</li>
                    <li>IP地址：{{ equipInfo.ipAddress }}</li>
                    <li>设备创建时间：{{ equipInfo.createTime }}</li>
                    <li>IMEI：{{ equipInfo.imei }}</li>
                    <li style="height: 21px" />
                    <li class="mapShow" @click="mapVisible = true">
                        查看地图
                    </li>
                </ul>
                <ul>
                    <li>设备状态：{{ equipInfo.status | statusTypeFilter }}</li>
                    <li>激活时间：{{ equipInfo.activationTime }}</li>
                </ul>
            </div>
            <div class="card-container">
                <a-tabs type="card">
                    <a-tab-pane key="1" tab="属性">
                        <ul class="cardListWrap">
                            <li v-for="item in propertiesList" :key="item.id" class="tabList">
                                <p>
                                    <span>{{ item.name }}</span>
                                    <span class="historyData" @click="historyDataShow(item)">历史数据</span>
                                </p>
                                <p>{{ item.name ? item.value : '-' }}</p>
                                <p>
                                    <span>{{ (item.time && moment(Number(item.time)).format('YYYY-MM-DD HH:mm')) || "*未更新" }}</span>
                                    | {{ item.dataType.type }} |
                                    <span>{{ item.accessMode | rwTypeFilter }}</span>
                                </p>
                            </li>
                        </ul>
                    </a-tab-pane>
                    <a-tab-pane v-if="isShow" key="2" tab="属性（未创建）">
                        <div style="display: flex; justify-content: flex-end">
                            <a-button style="margin-right: 30px" @click="gotoSetPage">
                                设置物模型
                            </a-button>
                            <a-button type="primary">
                                导出物模型
                            </a-button>
                        </div>
                        <ul style="text-align: center; margin-top: 30px">
                            <li @click="gotoSetPage">
                                <a-icon class="addIcon" type="plus-circle" />
                            </li>
                            <li>此产品尚未添加物模型，点击 “设置物模型” ，开启物模型编辑 ；添加功能点，并保存物模型</li>
                        </ul>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="事件记录">
                        <div class="recordWrap">
                            <!-- <a-select default-value="all" style="width: 240px;">
                                <a-select-option value="all">
                                    事件类型（全部）
                                </a-select-option>
                                <a-select-option value="warn">
                                    告警
                                </a-select-option>
                                <a-select-option value="error">
                                    故障
                                </a-select-option>
                                <a-select-option value="info">
                                    消息
                                </a-select-option>
                            </a-select> -->
                            <a-input-search v-model="eventsSearch.signInfo" placeholder="请输入标识符搜索" enter-button style="width: 300px; margin-right: 25px" @search="onSearch" />
                            <DateRangePicker :tip="['请选择记录查询时间段', '']" style="width: 400px" @chooseDate="eventsDate" @dateClear="dateClear" />
                        </div>
                        <a-table style="margin: 20px 0"
                                 :locale="{emptyText: '暂无数据'}"
                                 table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="eventsList">
                            <template slot="time" slot-scope="text, record">
                                <span>{{ (record.time && moment(Number(record.time)).format('YYYY-MM-DD HH:mm')) || "-" }}</span>
                            </template>
                        </a-table>
                        <Pagination :current-page="eventsSearch.page" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="文件记录">
                        <p style="display: flex; justify-content: space-between">
                            <span>当前账号存储空间已占用 <span style="color: #1890ff">0KB/1024MB</span> ； 该设备文件数量 <span style="color: #1890ff">0/1000</span></span>
                            <a-upload
                                name="file"
                                :multiple="true">
                                <a-button type="primary">
                                    上传文件
                                </a-button>
                            </a-upload>
                        </p>
                        <ul style="margin-top: 100px">
                            <li style="text-align: center">
                                <a-upload
                                    name="file"
                                    :multiple="true">
                                    <a-icon style="font-size: 40px" type="plus-circle" />
                                </a-upload>
                            </li>
                            <li style="text-align: center; margin: 20px 0 100px">
                                尚未上传文件
                            </li>
                            <li>*单个账号最多存储1G文件；单个设备最多存储1000个文件;单个文件在平台最多保存3个月；目前支持图片文件和文本文件</li>
                        </ul>
                    </a-tab-pane>
                    <a-tab-pane v-if="equipInfo.nodeType === 2" key="5" tab="子设备管理">
                        <p style="display: flex; justify-content: space-between">
                            <a-input-search placeholder="请输入设备名称搜索" enter-button style="width: 300px;" />
                            <a-button type="primary" @click="equipVisible = true">
                                关联子设备
                            </a-button>
                        </p>
                        <p>暂无数据</p>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <!-- 编辑设备信息弹窗 -->
        <a-modal
            :width="800"
            title="编辑设备信息"
            :visible="visible"
            ok-text="确认"
            cancel-text="取消"
            @ok="onSubmit"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form">
                <a-form-model-item ref="name" label="设备code" prop="name">
                    <a-input
                        v-model="form.code"
                        disabled
                        placeholder="最长不超过32位，英文，数字及特殊字符" />
                </a-form-model-item>
                <a-form-model-item label="设备描述" prop="desc">
                    <a-textarea
                        v-model="form.desc" />
                </a-form-model-item>
                <a-form-model-item>
                    <Map :search-val="form.address" :site="editSite.site" @onSelect="chooseSite" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 查看地图弹窗 -->
        <a-modal
            title="地图"
            :visible="mapVisible"
            @cancel="mapVisible = false">
            <Map :is-show-input="false" :search-val="searchVal" :site="equipSite" />
            <template slot="footer">
                <a-button @click="mapVisible = false">
                    关闭
                </a-button>
            </template>
        </a-modal>
        <!-- 历史数据弹窗 -->
        <a-modal
            :width="800"
            title="功能点历史数据"
            :visible="dataVisible"
            :footer="null"
            @cancel="dataVisible = false">
            <p>
                <span>功能名称：{{ historyData.name }}</span>
                <span style="margin: 0 40px">标识符：{{ historyData.identifier }}</span>
                <span>数据类型：{{ historyData.dataType && historyData.dataType.type }}</span>
            </p>
            <DateRangePicker style="width: 400px" :tip="['请选择想查询的时间段', '']" @chooseDate="nowDate" @dateClear="onDateClear" />
            <p v-if="historyTable.length === 0" style="margin: 60px auto 40px; text-align: center">
                暂无数据
            </p>
            <ul v-else class="historyList">
                <li v-for="(item, index) in historyTable" :key="index">
                    <span>{{ (item.time && moment(Number(item.time)).format('YYYY-MM-DD HH:mm')) || "-" }}</span>
                    <span>{{ item[historyData.identifier] }}</span>
                </li>
            </ul>
            <Pagination :current-page="rangeDate.page" :count="historyTotal" @handleChange="historyChangePage" @handleChangeSize="historyChangeSize" />
        </a-modal>
        <!-- 关联子设备弹窗 -->
        <a-modal
            :width="900"
            title="关联子设备"
            ok-text="确认"
            cancel-text="取消"
            :visible="equipVisible"
            @ok="onRelation"
            @cancel="equipVisible = false">
            <div>
                <span style="margin-right: 60px">选择产品</span>
                <a-select v-model="selectedProduct" placeholder="请选择产品" style="width: 220px" @change="onSelected">
                    <a-select-option v-for="item in productListInfo" :key="item.id" :value="item.code">
                        {{ item.productName }}
                    </a-select-option>
                </a-select>
            </div>
            <ul class="dialogWrap">
                <li>
                    <p>设备列表</p>
                    <div class="dialogBox">
                        <a-input-search v-model="equipSearch.equipKeywords" enter-button placeholder="请输入设备code搜索" @search="nodeEquipSearch" />
                        <a-table style="margin: 20px 0;"
                                 :locale="{emptyText: '暂无数据'}"
                                 table-layout="auto" :columns="nodeColumns" :pagination="false" row-key="id" :data-source="nodeData">
                            <template slot="time" slot-scope="text, record">
                                <span>{{ record.eventType | eventsTypeFilter }}</span>
                            </template>
                        </a-table>
                        <Pagination :simple="true" :current-page="equipSearch.page" :count="equipTotal" @handleChange="onChangePage" @handleChangeSize="onChangeSize" />
                    </div>
                </li>
                <li style="margin: 0 20px">
                    <a-icon type="arrow-right" style="font-size: 40px" />
                </li>
                <li>
                    <p>已选择</p>
                    <div class="dialogBox" style="width: 300px" />
                </li>
            </ul>
        </a-modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import Map from "@/components/Map"
import DateRangePicker from "@/components/DateRangePicker"
import moment from "moment"
import { searchEquip, changeEquip, EquipPropertyList, EquipPropertyHistory, getEquipList, getEquipRecords } from "@/api/equipment"
import { productList } from "@/api/product"
const columns = [
    {
        title: '功能名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '标识符',
        dataIndex: 'identifier',
        key: 'identifier'
    },
    // {
    //     title: '事件类型',
    //     scopedSlots: { customRender: 'type' }
    // },
    {
        title: '输出参数',
        dataIndex: 'outputParam',
        key: 'outputParam'
    },
    {
        title: '更新时间',
        scopedSlots: { customRender: 'time' }
    },
]
const nodeColumns = [
    {
        title: '设备code',
        dataIndex: 'code',
        key: 'code'
    }
]
export default {
    name: "EquipDetailsCon",
    components: {
        Map,
        DateRangePicker,
        Pagination
    },
    data() {
        return {
            nodeColumns,
            nodeData: [],
            moment,
            equipTotal: 0,
            // 历史数据总条数
            historyTotal: 0,
            // 事件记录总条数
            total: 0,
            // 设备id
            id: '',
            columns,
            // 设备详情
            equipInfo: {},
            // 网关设备的所有产品列表
            productListInfo: [],
            // 查看地图弹窗中的地址内容
            searchVal: '',
            editSite: {},
            // 设备完整地址
            equipSite: '',
            // 编辑产品信息弹窗是否可见
            visible: false,
            // 查看地图弹窗是否可见
            mapVisible: false,
            // 历史数据弹窗是否可见
            dataVisible: false,
            // 关联子设备弹窗是否可见
            equipVisible: false,
            // 当前选中的产品
            selectedProduct: undefined,
            // 编辑产品信息表单数据
            form: {
                code: '',
                address: '',
                desc: ''
            },
            isShow: true,
            // 属性列表数据
            propertiesList: [],
            // 事件列表数据
            eventsList: [],
            // 功能点历史数据弹窗中的时间段
            rangeDate: {
                page: 1,
                pageSize: 10,
                start: '',
                end: ''
            },
            // 当前查看的属性历史数据
            historyData: [],
            historyTable: [],
            // 事件记录列表查询参数
            eventsSearch: {
                page: 1,
                pageSize: 10,
                signInfo: '',
                startTime: '',
                endTime: ''
            },
            // 关联子设备查询参数
            equipSearch: {
                page: 1,
                pageSize: 10,
                equipKeywords: ''
            }
        }
    },
    created() {
        let equipId = this.$route.query.id
        this.id = equipId
        this.equipData()
        productList(3).then((res) => {
            this.productListInfo = res.data
        }).catch(error => {
            console.log(error)
        })
    },
    methods: {
        // 查询未绑定父设备的数据
        searchEquip() {
            getEquipList(this.equipSearch.page, this.equipSearch.pageSize, this.equipSearch.equipKeywords, this.selectedProduct, '', '', '', '', '', '', 1).then((res) => {
                let info = res.data
                this.nodeData = info.records
                this.equipTotal = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 设备信息
        equipData() {
            searchEquip(this.id).then((res) => {
                let info = res.data,
                    siteInfo = '',
                    dataFlag = info.metaData ? JSON.parse(info.metaData) : {events: [], services: [], properties: []}
                siteInfo = info.longitude ? `X : ${info.longitude} Y : ${info.latitude} ${info.address}` : ''
                this.equipInfo = info
                this.isShow = dataFlag.events.length === 0 && dataFlag.services.length === 0 && dataFlag.properties.length === 0
                this.form = {
                    code: info.code,
                    desc: info.remark,
                    address: info.address
                }
                this.searchVal = info.address
                this.equipSite = siteInfo
                this.editSite.site = siteInfo
                EquipPropertyList(info.productCode, info.code).then((res) => {
                    this.propertiesList = res.data ? res.data.properties : []
                }).catch(error => {
                    console.log(error)
                })
                this.getEventsData(1)
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取事件记录列表数据
        getEventsData(val) {
            this.eventsSearch.page = val
            let events = this.eventsSearch
            getEquipRecords(this.equipInfo.code, events.endTime, events.page, events.pageSize, this.equipInfo.productCode, events.startTime, 2, events.signInfo).then((res) => {
                let info = res.data || {}
                this.eventsList = info.records
                this.total = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择事件记录页码
        changePage(val) {
            this.eventsSearch.page = val
            this.getEventsData(val)
        },
        // 修改事件记录每页数据条数
        changeSize(val) {
            this.eventsSearch.pageSize = val
            this.getEventsData(1)
        },
        // 关联子设备列表条件查询
        nodeEquipSearch() {
            this.searchEquip()
        },
        // 选择关联子设备分页
        onChangePage(val) {
            this.equipSearch.page = val
            this.searchEquip()
        },
        // 修改关联子设备吧每页数据条数
        onChangeSize(val) {
            this.equipSearch.pageSize = val
            this.searchEquip()
        },
        // 编辑后的设备详情
        equipUpdate() {
            searchEquip(this.id).then((res) => {
                let info = res.data
                this.equipInfo = info
                this.equipSite = info.longitude ? `X : ${info.longitude} Y : ${info.latitude} ${info.address}` : ''
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择编辑设备弹窗中的地址
        chooseSite(val) {
            this.form.address = val.title
            this.editSite = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: 'X' + ':' + val.point.lat + ' ' + 'Y' + ':' + val.point.lng + ' ' + val.title
            }
        },
        // 跳转到产品详情页进行设置物模型
        gotoSetPage() {
            this.$emit('onProductDetails', this.equipInfo.productId)
        },
        // 查看历史数据
        historyDataShow(val) {
            this.dataVisible = true
            this.historyData = val
            this.getPropertyHistory(1)
        },
        // 历史数据列表选择分页
        historyChangePage(val) {
            this.rangeDate.page = val
            this.getPropertyHistory(val)
        },
        // 历史数据列表选择每页显示的数据条数
        historyChangeSize(val) {
            this.rangeDate.pageSize = val
            this.getPropertyHistory(1)
        },
        // 获取属性历史数据列表
        getPropertyHistory(val) {
            this.rangeDate.page = val
            let info = this.rangeDate
            EquipPropertyHistory(this.historyData.identifier, this.equipInfo.code, info.end, this.equipInfo.productCode, info.start, 1, info.page, info.pageSize).then((res) => {
                let info = res.data
                this.historyTable = info.records
                this.historyTotal = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择功能点历史数据弹窗中的时间段
        nowDate(val) {
            this.rangeDate = {
                page: 1,
                pageSize: 10,
                start: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            this.getPropertyHistory(1)
        },
        onDateClear(val) {
            if (val === 0) {
                this.rangeDate = {
                    page: 1,
                    pageSize: 10,
                    start: '',
                    end: ''
                }
                this.getPropertyHistory(1)
            }
        },
        // 事件记录列表日期查询操作
        eventsDate(val) {
            this.eventsSearch.startTime = moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
            this.eventsSearch.endTime = moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            this.getEventsData(1)
        },
        // 日期清空后的回调
        dateClear(val) {
            if(val === 0) {
                this.eventsSearch.startTime = ''
                this.eventsSearch.endTime = ''
                this.getEventsData(1)
            }
        },
        // 关联子设备弹窗中的确定操作
        onRelation() {
            this.$message.success('关联成功！')
            this.equipVisible = false
        },
        // 关联子设备弹窗中选择产品操作
        onSelected() {
            this.equipSearch.page = 1
            this.searchEquip()
        },
        // 事件记录列表查询操作
        onSearch(key) {
            this.eventsSearch.signInfo = key
            this.getEventsData(1)
        },
        // 点击设备档案列表中的详情
        gotoDetails(val) {
            console.log(val)
        },
        // 选择设备状态
        equipSataus(val) {
            console.log(val)
        },
        // 选择创建方式
        createType(val) {
            console.log(val)
        },
        // 选择功能类型
        funType(val) {
            console.log(val)
        },
        // 选择功能类别
        funCata(val) {
            console.log(val)
        },
        // 关键字搜索物模型管理/0
        onKeySearch(key) {
            console.log(key)
        },
        // 点击物模型管理/0中的查看操作
        showInfo(key) {
            console.log(key)
        },
        // 点击物模型管理/0中的删除操作
        onDelete(key) {
            console.log(key)
        },
        // 点击编辑设备信息弹窗中的确定按钮
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    changeEquip(this.form.code, '', this.editSite.siteX, this.editSite.siteY, this.form.address, this.form.desc, this.id).then(() => {
                        this.visible = false
                        this.$message.success('编辑成功！')
                        this.equipUpdate()
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 获取用户选择的省份城市数据
        getCityInfo(val) {
            console.log(val)
        },
        // 选择编辑产品信息弹窗中的联网方式
        onChange(val) {
            console.log(val)
        },
        // 选择最近添加时间段
        nowAddDate(val) {
            console.log(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.detailsTitle {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
}
.detailsChoose {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px
}
.detailsList {
    margin: 40px 40px 30px;
    display: flex;
    justify-content: space-between;
    ul li {
        margin-bottom: 12px;
    }
}
.mapShow {
    color: #1890ff;
    cursor: pointer;
}
.card-container {
    background: #f5f5f5;
    min-height: 400px;
    overflow: hidden;
    padding: 24px;
    .cardListWrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px
    }
}
.card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane > .ant-table-wrapper{
    background: #fff;
    padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
.addProduct {
    ::v-deep .ant-form-item-control-wrapper {
        flex: 1;
    }
}
.tabList {
    width: 30%;
    margin: 0 5% 25px 0;
    padding: 14px 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #F0F0F0;
    color: #4D4F5C;
    background: #fff;
    .historyData {
        float: right;
        cursor: pointer;
        color: #1890ff;
    }
}
.tabList:nth-of-type(3n) {
    margin-right: 0;
}
.addIcon {
    font-size: 40px;
    margin-bottom:16px;
    color: #1890ff;
    cursor: pointer;
}
.recordWrap {
    display: flex;
}
::v-deep .ant-pagination {
    text-align: right;
}
::v-deep .ant-pagination-total-text {
    float: left;
}
.historyList {
    margin-top: 20px;
    max-height: 300px;
    overflow: auto;
    li {
        display: flex;
        justify-content: space-between;
        padding: 0 30px 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }
}
.dialogWrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .dialogBox {
        width: 400px;
        padding: 20px;
        height: 400px;
        overflow: auto;
        border: 1px solid #ccc
    }
}
</style>
