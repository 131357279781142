var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("span", { staticClass: "detailsTitle" }, [_vm._v("设备信息")]),
        _c(
          "span",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                _vm.visible = true
              },
            },
          },
          [_c("a-icon", { attrs: { type: "edit" } }), _vm._v(" 编辑设备信息")],
          1
        ),
        _c("div", { staticClass: "detailsList" }, [
          _c("ul", [
            _c("li", [
              _vm._v("设备所属产品：" + _vm._s(_vm.equipInfo.productName)),
            ]),
            _c("li", [
              _vm._v(
                "设备节点类型：" +
                  _vm._s(_vm._f("nodeTypeFilter")(_vm.equipInfo.nodeType))
              ),
            ]),
            _c("li", [
              _vm._v("最近在线时间：" + _vm._s(_vm.equipInfo.latestOnlineTime)),
            ]),
            _c("li", [
              _vm._v("设备密匙(DeviceKey)：" + _vm._s(_vm.equipInfo.keyInfo)),
            ]),
            _c("li", [_vm._v("设备描述：" + _vm._s(_vm.equipInfo.remark))]),
            _c("li", [_vm._v("位置信息：" + _vm._s(_vm.equipSite))]),
          ]),
          _c("ul", [
            _c("li", [_vm._v("设备code：" + _vm._s(_vm.equipInfo.code))]),
            _c("li", [_vm._v("IP地址：" + _vm._s(_vm.equipInfo.ipAddress))]),
            _c("li", [
              _vm._v("设备创建时间：" + _vm._s(_vm.equipInfo.createTime)),
            ]),
            _c("li", [_vm._v("IMEI：" + _vm._s(_vm.equipInfo.imei))]),
            _c("li", { staticStyle: { height: "21px" } }),
            _c(
              "li",
              {
                staticClass: "mapShow",
                on: {
                  click: function ($event) {
                    _vm.mapVisible = true
                  },
                },
              },
              [_vm._v(" 查看地图 ")]
            ),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                "设备状态：" +
                  _vm._s(_vm._f("statusTypeFilter")(_vm.equipInfo.status))
              ),
            ]),
            _c("li", [
              _vm._v("激活时间：" + _vm._s(_vm.equipInfo.activationTime)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "card-container" },
          [
            _c(
              "a-tabs",
              { attrs: { type: "card" } },
              [
                _c("a-tab-pane", { key: "1", attrs: { tab: "属性" } }, [
                  _c(
                    "ul",
                    { staticClass: "cardListWrap" },
                    _vm._l(_vm.propertiesList, function (item) {
                      return _c(
                        "li",
                        { key: item.id, staticClass: "tabList" },
                        [
                          _c("p", [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "span",
                              {
                                staticClass: "historyData",
                                on: {
                                  click: function ($event) {
                                    return _vm.historyDataShow(item)
                                  },
                                },
                              },
                              [_vm._v("历史数据")]
                            ),
                          ]),
                          _c("p", [
                            _vm._v(_vm._s(item.name ? item.value : "-")),
                          ]),
                          _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (item.time &&
                                    _vm
                                      .moment(Number(item.time))
                                      .format("YYYY-MM-DD HH:mm")) ||
                                    "*未更新"
                                )
                              ),
                            ]),
                            _vm._v(" | " + _vm._s(item.dataType.type) + " | "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("rwTypeFilter")(item.accessMode))
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm.isShow
                  ? _c(
                      "a-tab-pane",
                      { key: "2", attrs: { tab: "属性（未创建）" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                            },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "30px" },
                                on: { click: _vm.gotoSetPage },
                              },
                              [_vm._v(" 设置物模型 ")]
                            ),
                            _c("a-button", { attrs: { type: "primary" } }, [
                              _vm._v(" 导出物模型 "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "30px",
                            },
                          },
                          [
                            _c(
                              "li",
                              { on: { click: _vm.gotoSetPage } },
                              [
                                _c("a-icon", {
                                  staticClass: "addIcon",
                                  attrs: { type: "plus-circle" },
                                }),
                              ],
                              1
                            ),
                            _c("li", [
                              _vm._v(
                                "此产品尚未添加物模型，点击 “设置物模型” ，开启物模型编辑 ；添加功能点，并保存物模型"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a-tab-pane",
                  { key: "3", attrs: { tab: "事件记录" } },
                  [
                    _c(
                      "div",
                      { staticClass: "recordWrap" },
                      [
                        _c("a-input-search", {
                          staticStyle: {
                            width: "300px",
                            "margin-right": "25px",
                          },
                          attrs: {
                            placeholder: "请输入标识符搜索",
                            "enter-button": "",
                          },
                          on: { search: _vm.onSearch },
                          model: {
                            value: _vm.eventsSearch.signInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventsSearch, "signInfo", $$v)
                            },
                            expression: "eventsSearch.signInfo",
                          },
                        }),
                        _c("DateRangePicker", {
                          staticStyle: { width: "400px" },
                          attrs: { tip: ["请选择记录查询时间段", ""] },
                          on: {
                            chooseDate: _vm.eventsDate,
                            dateClear: _vm.dateClear,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-table", {
                      staticStyle: { margin: "20px 0" },
                      attrs: {
                        locale: { emptyText: "暂无数据" },
                        "table-layout": "auto",
                        columns: _vm.columns,
                        pagination: false,
                        "row-key": "id",
                        "data-source": _vm.eventsList,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "time",
                          fn: function (text, record) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (record.time &&
                                      _vm
                                        .moment(Number(record.time))
                                        .format("YYYY-MM-DD HH:mm")) ||
                                      "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("Pagination", {
                      attrs: {
                        "current-page": _vm.eventsSearch.page,
                        count: _vm.total,
                      },
                      on: {
                        handleChange: _vm.changePage,
                        handleChangeSize: _vm.changeSize,
                      },
                    }),
                  ],
                  1
                ),
                _c("a-tab-pane", { key: "4", attrs: { tab: "文件记录" } }, [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v("当前账号存储空间已占用 "),
                        _c("span", { staticStyle: { color: "#1890ff" } }, [
                          _vm._v("0KB/1024MB"),
                        ]),
                        _vm._v(" ； 该设备文件数量 "),
                        _c("span", { staticStyle: { color: "#1890ff" } }, [
                          _vm._v("0/1000"),
                        ]),
                      ]),
                      _c(
                        "a-upload",
                        { attrs: { name: "file", multiple: true } },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 上传文件 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ul", { staticStyle: { "margin-top": "100px" } }, [
                    _c(
                      "li",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "a-upload",
                          { attrs: { name: "file", multiple: true } },
                          [
                            _c("a-icon", {
                              staticStyle: { "font-size": "40px" },
                              attrs: { type: "plus-circle" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "text-align": "center",
                          margin: "20px 0 100px",
                        },
                      },
                      [_vm._v(" 尚未上传文件 ")]
                    ),
                    _c("li", [
                      _vm._v(
                        "*单个账号最多存储1G文件；单个设备最多存储1000个文件;单个文件在平台最多保存3个月；目前支持图片文件和文本文件"
                      ),
                    ]),
                  ]),
                ]),
                _vm.equipInfo.nodeType === 2
                  ? _c(
                      "a-tab-pane",
                      { key: "5", attrs: { tab: "子设备管理" } },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c("a-input-search", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                placeholder: "请输入设备名称搜索",
                                "enter-button": "",
                              },
                            }),
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.equipVisible = true
                                  },
                                },
                              },
                              [_vm._v(" 关联子设备 ")]
                            ),
                          ],
                          1
                        ),
                        _c("p", [_vm._v("暂无数据")]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "编辑设备信息",
            visible: _vm.visible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.onSubmit,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form } },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "设备code", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: "",
                      placeholder: "最长不超过32位，英文，数字及特殊字符",
                    },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "设备描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                [
                  _c("Map", {
                    attrs: {
                      "search-val": _vm.form.address,
                      site: _vm.editSite.site,
                    },
                    on: { onSelect: _vm.chooseSite },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "地图", visible: _vm.mapVisible },
          on: {
            cancel: function ($event) {
              _vm.mapVisible = false
            },
          },
        },
        [
          _c("Map", {
            attrs: {
              "is-show-input": false,
              "search-val": _vm.searchVal,
              site: _vm.equipSite,
            },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mapVisible = false
                    },
                  },
                },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "功能点历史数据",
            visible: _vm.dataVisible,
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.dataVisible = false
            },
          },
        },
        [
          _c("p", [
            _c("span", [_vm._v("功能名称：" + _vm._s(_vm.historyData.name))]),
            _c("span", { staticStyle: { margin: "0 40px" } }, [
              _vm._v("标识符：" + _vm._s(_vm.historyData.identifier)),
            ]),
            _c("span", [
              _vm._v(
                "数据类型：" +
                  _vm._s(
                    _vm.historyData.dataType && _vm.historyData.dataType.type
                  )
              ),
            ]),
          ]),
          _c("DateRangePicker", {
            staticStyle: { width: "400px" },
            attrs: { tip: ["请选择想查询的时间段", ""] },
            on: { chooseDate: _vm.nowDate, dateClear: _vm.onDateClear },
          }),
          _vm.historyTable.length === 0
            ? _c(
                "p",
                {
                  staticStyle: {
                    margin: "60px auto 40px",
                    "text-align": "center",
                  },
                },
                [_vm._v(" 暂无数据 ")]
              )
            : _c(
                "ul",
                { staticClass: "historyList" },
                _vm._l(_vm.historyTable, function (item, index) {
                  return _c("li", { key: index }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (item.time &&
                            _vm
                              .moment(Number(item.time))
                              .format("YYYY-MM-DD HH:mm")) ||
                            "-"
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(item[_vm.historyData.identifier])),
                    ]),
                  ])
                }),
                0
              ),
          _c("Pagination", {
            attrs: {
              "current-page": _vm.rangeDate.page,
              count: _vm.historyTotal,
            },
            on: {
              handleChange: _vm.historyChangePage,
              handleChangeSize: _vm.historyChangeSize,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 900,
            title: "关联子设备",
            "ok-text": "确认",
            "cancel-text": "取消",
            visible: _vm.equipVisible,
          },
          on: {
            ok: _vm.onRelation,
            cancel: function ($event) {
              _vm.equipVisible = false
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("span", { staticStyle: { "margin-right": "60px" } }, [
                _vm._v("选择产品"),
              ]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "220px" },
                  attrs: { placeholder: "请选择产品" },
                  on: { change: _vm.onSelected },
                  model: {
                    value: _vm.selectedProduct,
                    callback: function ($$v) {
                      _vm.selectedProduct = $$v
                    },
                    expression: "selectedProduct",
                  },
                },
                _vm._l(_vm.productListInfo, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.code } },
                    [_vm._v(" " + _vm._s(item.productName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("ul", { staticClass: "dialogWrap" }, [
            _c("li", [
              _c("p", [_vm._v("设备列表")]),
              _c(
                "div",
                { staticClass: "dialogBox" },
                [
                  _c("a-input-search", {
                    attrs: {
                      "enter-button": "",
                      placeholder: "请输入设备code搜索",
                    },
                    on: { search: _vm.nodeEquipSearch },
                    model: {
                      value: _vm.equipSearch.equipKeywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipSearch, "equipKeywords", $$v)
                      },
                      expression: "equipSearch.equipKeywords",
                    },
                  }),
                  _c("a-table", {
                    staticStyle: { margin: "20px 0" },
                    attrs: {
                      locale: { emptyText: "暂无数据" },
                      "table-layout": "auto",
                      columns: _vm.nodeColumns,
                      pagination: false,
                      "row-key": "id",
                      "data-source": _vm.nodeData,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "time",
                        fn: function (text, record) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("eventsTypeFilter")(record.eventType)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("Pagination", {
                    attrs: {
                      simple: true,
                      "current-page": _vm.equipSearch.page,
                      count: _vm.equipTotal,
                    },
                    on: {
                      handleChange: _vm.onChangePage,
                      handleChangeSize: _vm.onChangeSize,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "li",
              { staticStyle: { margin: "0 20px" } },
              [
                _c("a-icon", {
                  staticStyle: { "font-size": "40px" },
                  attrs: { type: "arrow-right" },
                }),
              ],
              1
            ),
            _c("li", [
              _c("p", [_vm._v("已选择")]),
              _c("div", {
                staticClass: "dialogBox",
                staticStyle: { width: "300px" },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }